import gsap from "gsap/all";
import { qs, fetchJSON } from "../utils/lib";
import Selectr from "mobius1-selectr";

export default class StaticNl {
    constructor() {
        this.el = qs(".static_nl");
        this.form = qs('#nl_form_new')
        this.email = this.el.querySelector('#nl_email')
        this.terms = this.el.querySelector('#newsl_privacy')
        this.display = "flex";
        this.email_ok = false
        this.terms_ok = false
        this.select_ok = false
        this.submit = this.el.querySelector('.newsletter-popup-submit')
        this.text = this.el.querySelector('.text')
        this.thank_message = this.el.querySelector('.thank_message')
        this.select = this.el.querySelector('select.lang')
        this.select_value = ""
        //this.select_plugin = new Selectr(this.select, {
        //    defaultSelected: true,
        //    customClass: "newsletter_language",
        //    searchable: false,
        //  });
    }

    init() {
        //this.select_plugin.on('selectr.change', (option) => {
        //    this.setSelectValue(option)
        //})


        this.terms.addEventListener("change", () => {
            this.checkTerms();
        });
        this.form.addEventListener("submit", (e) => {
            e.preventDefault()
            this.submit_form(e);
        });


    }


    validateEmail(email) {
        var re =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    checkEmail() {
        if (this.validateEmail(this.email.value)) {
            this.email_ok = true;
        } else {
            this.email_ok = false;
        }
        this.checkForm();
    }
    checkTerms() {
        if (this.terms.checked) {
            this.terms_ok = true;
        } else {
            this.terms_ok = false;
        }
        this.checkForm();
    }
    setSelectValue(option) {
        this.select_value = option.value
        this.checkSelect()
    }
    checkSelect() {
        if (this.select_value == "notselected") {
            this.select_ok = false
        } else {
            this.select_ok = true
        }
        this.checkForm()
    }
    checkForm() {
        if (this.email_ok && this.terms_ok && this.select_ok) {
            this.submit.classList.remove("disabled");
        }
    }
    async submit_form(e) {

        if (typeof gtag !== "undefined") {
            gtag("event", "submit", {
                event_category: "newsletter",
                event_label: "newsletter_subscribe",
            });
        }
        const myFormData = new FormData(this.form);
        const formDataObj = {};
        myFormData.forEach((value, key) => (formDataObj[key] = value));

        const AUTH_TOKEN = document.querySelector("meta[name=csrf-token]").getAttribute("content");
        const url = "/en/subscribe";
        const post = {
            body: formDataObj,
            email: this.email.value,
            authenticity_token: AUTH_TOKEN,
            language: this.select.value
        };
        const response = await fetchJSON(url, {
            method: "POST",
            body: JSON.stringify(post),
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                "X-Requested-With": "xmlhttprequest" /* XMLHttpRequest is ok too, it's case insensitive */,
            },
        })
        if (response == 'ok') {
            e.preventDefault()
            this.text.classList.add('hidden')
            this.thank_message.classList.add('active')

        }



    }

}