import { qs, qsa } from "../../utils/lib";
import gsap from "gsap";
import Selectr from "mobius1-selectr";

export default class Carousel3Slide {
  constructor(el) {
    this.el = el;
    this.options = qsa("#categories-option", this.el);
    this.carousels = qsa(".swiper3Cols", this.el);
    this.select = qs(".home-carousel-3slide__select", this.el);
    this.select_carousel = new Selectr(qs(".js_select_carousel", this.el), {
      defaultSelected: true,
      customClass: "home-carousel-3slide__select",
      searchable: false,
    });
  }

  init() {
    this.carousels.forEach((carousel, i) => {
      if (carousel.id == this.select_carousel.getValue()) {
        gsap.set(carousel, {
          autoAlpha: 1,
        });
      } else {
        gsap.set(carousel, {
          autoAlpha: 0,
          y: 20,
        });
      }
    });

    this.select_carousel.on("selectr.change", (option) => {
      this.carousels.forEach((carousel, i) => {
        if (carousel.id == option.value) {
          gsap.to(carousel, {
            autoAlpha: 1,
            duration: 0.8,
            opacity: 1,
            y: 0,
            ease: "power1.inOut",
          });
        } else {
          gsap.to(carousel, {
            autoAlpha: 0,
            duration: 0.5,
            opacity: 1,
            y: 20,
            ease: "power1.inOut",
          });
        }
      });
    });
  }
}
