import gsap from "gsap/all";
import { qs, qsa } from "../../utils/lib";
import FiltersAccordionMobile from "./FiltersAccordionMobile";

export default class FiltersPopup {
  constructor() {
    this.el = qs(".filters-popup");
    this.closeBtn = qs(".filters-popup__closeX");
    this.popupContent = this.el
    this.display = "initial";
    this.trigger_btn = qs(".js-filters");
    this.animating = false;
    this.accordions = qsa(".js-accordion-filter");
    this.reset_filters = qs('.filters-popup__clear-filters', this.el)
    this.checkboxes = qsa('input[type="checkbox"]', this.el)
    this.box_filters = qsa('.single-filter__flex', this.el)
  }

  init() {
    if (this.trigger_btn) {
      this.trigger_btn.addEventListener("click", this.popupIn.bind(this));
    }
    this.box_filters.forEach(filter => {
        filter.addEventListener('click', this.toggleCheckbox.bind(this, filter))
    })
    this.closeBtn.addEventListener("click", () => {
      this.closePopup();
    });
    this.reset_filters && 
      this.reset_filters.addEventListener('click', this.reset.bind(this))
    if (window.innerWidth < 890) {
      this.accordions.forEach((accordion) => {
        const acc = new FiltersAccordionMobile(accordion);
        acc.init();
      });
    }
  }

  toggleCheckbox(box) {
    const checkbox = box.querySelector('input')
    checkbox.checked = !checkbox.checked
  }

  popupIn() {
    document.querySelector("html").style.overflow = "hidden";
    document.querySelector("body").style.overflow = "hidden";

    this.tl = gsap.timeline();
    this.animating = true;

    gsap.to(this.el, {
      display: this.display,
    });

    this.tl.fromTo(
      this.popupContent,
      {
        autoAlpha: 0,
        scale: 0.95,
        y: -50,
      },
      {
        autoAlpha: 1,
        scale: 1,
        ease: "Expo.easeOut",
        duration: 1,
        y: 0,
        onComplete: () => {
          this.animating = false;
        },
      }
    );
  }
  closePopup() {
    document.querySelector("html").style.overflow = "initial";
    document.querySelector("body").style.overflow = "initial";

    this.animating = true;

    gsap.fromTo(
      this.popupContent,
      {
        autoAlpha: 1,
        scale: 1,
        duration: 1,
        y: 0,
      },
      {
        autoAlpha: 0,
        scale: 0.95,
        y: -50,
        ease: "Expo.easeOut",

        onComplete: () => {
          this.animating = false;
        },
      }
    );

    gsap.to(this.el, {
      display: "none",
    });
  }
  reset(event) {
    this.checkboxes.forEach(box => box.checked = false)
    event.preventDefault()
  }
}
