import { qsa } from "../utils/lib";
import gsap from "gsap";

export default class HomeEmporium {
  constructor(el) {
    this.el = el;
    this.buttonList = qsa(".dot-filters-bar button");
    this.menuContainerList = qsa(".js-dot-filters");
    this.imageContainer = qsa(".home-emporium_img_container");
    this.firstImg = qsa(".js-first-img");
    this.lastImg = qsa(".js-last-img");
  }

  init() {
    this.buttonList.forEach((button, index) => {
      button.addEventListener("click", () => {
        //Remove active at image
        for (let i = 0; i < this.imageContainer.length; i++) {
          this.imageContainer[i].classList.remove("active");
        }

        //Remove active at button
        for (let i = 0; i < this.buttonList.length; i++) {
          this.buttonList[i].classList.remove("active");
        }

        //Remove active at content
        if (index == this.menuContainerList[index].dataset.index) {
          for (let i = 0; i < this.menuContainerList.length; i++) {
            this.menuContainerList[i].classList.remove("active");
          }
        }

        //Elements animation
        gsap.from(this.firstImg[index], {
          x: -10,
          ease: "power2.inOut",
        });
        gsap.set(this.menuContainerList[index].children, {
          y: -10,
          opacity: 0
        });
        gsap.to(this.menuContainerList[index].children, {
          y: 0,
          opacity: 1,
          stagger: 0.1,
          ease: "power2.inOut",
        });
        gsap.from(this.lastImg[index], {
          x: 10,
          ease: "power2.inOut",
        });

        //Assign active class
        this.menuContainerList[index].classList.add("active");
        this.buttonList[index].classList.add("active");
        this.firstImg[index].classList.add("active");
        this.lastImg[index].classList.add("active");
      });
    });
  }
}
