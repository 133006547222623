import gsap from "gsap/all";
import { qs,qsa } from "../../utils/lib";

export default class SizeguidePopup {
  constructor() {
    this.el = qs(".size-guide");
    this.closeBtn = qs(".size-guide_popup__close");
    this.popupContent = qs(".size-guide_popup");
    this.display = "flex";
    this.animating = false;

    this.materialGuideButton = qs("#material-guide-button");
    this.sizeGuideButton = qs("#size-guide-button");

    this.materialGuideButtonText = qs("#material-guide-button .title");
    this.sizeGuideButtonText = qs("#size-guide-button .title");

    this.sizeGuideContent = qs("#size-guide-content");
    this.sizeGuideGarmentsContent = qs("#size-guide-garments-content");
    this.materialGuideContent = qs("#materials-guide-content");

    this.sizeSwitch = qs(".un-switch");
    this.sizeSwitchButton = qs(".un-switch_inner");
    this.tables = qsa('.size-tables')
  }
  init() {
    this.popupIn();

    this.closeBtn.addEventListener("click", () => {
      this.closePopup();
    });
    if ( this.sizeGuideButton ) {
      this.sizeGuideButton.addEventListener("click", () => {
        this.materialGuideContent.classList.add("disabled");
        this.sizeGuideContent.classList.remove("disabled");
        this.sizeGuideButtonText.classList.add("active");
        this.materialGuideButtonText.classList.remove("active");
      });

    }
    
    this.materialGuideButton && this.materialGuideButton.addEventListener("click", () => {
      this.sizeGuideContent.classList.add("disabled");
      this.materialGuideContent.classList.remove("disabled");
      this.materialGuideButtonText.classList.add("active");
      this.sizeGuideButtonText.classList.remove("active");
    });

    this.sizeSwitch.addEventListener("click", () => {
      this.sizeSwitchButton.classList.toggle("inch");
      this.tables.forEach(table => table.classList.toggle('disabled'))
    });
  }

  popupIn() {
    document.querySelector("html").style.overflow = "hidden";
    document.querySelector("body").style.overflow = "hidden";

    this.tl = gsap.timeline();
    this.animating = true;

    gsap.to(this.el, {
      display: this.display,
    });

    this.tl.fromTo(
      this.popupContent,
      {
        autoAlpha: 0,
        scale: 0.95,
        y: -50,
      },
      {
        autoAlpha: 1,
        scale: 1,
        ease: "Expo.easeOut",
        duration: 1,
        y: 0,
        onComplete: () => {
          this.animating = false;
        },
      }
    );
  }
  closePopup() {
    document.querySelector("html").style.overflow = "initial";
    document.querySelector("body").style.overflow = "initial";

    this.animating = true;

    gsap.fromTo(
      this.popupContent,
      {
        autoAlpha: 1,
        scale: 1,
        duration: 1,
        y: 0,
      },
      {
        autoAlpha: 0,
        scale: 0.95,
        y: -50,
        ease: "Expo.easeOut",

        onComplete: () => {
          this.animating = false;
        },
      }
    );

    gsap.to(this.el, {
      display: "none",
    });
  }
}
