import gsap from "gsap/all";
import { qs, qsa } from "../utils/lib";

export default class CheckoutAccordionMobile {
  constructor(el) {
    this.accordion = el;
    this.detail = qs(".js-accordion-checkout_detail", el);
    this.summary = qs(".js-accordion-checkout_summary", el);
    this.cta = qs(".js-accordion-checkout_summary-cta")
    this.isOpen = false;
    this.prevWidth = window.innerWidth
  }
  init() {
    if (window.innerWidth <= 890) {
      gsap.set(this.detail, {
        height: 0,
        autoAlpha: 0,
        duration: 0,
      });
    }

    this.summary.addEventListener("click", this.toggleAccordion.bind(this));

    window.addEventListener("resize", () => {
      // run function only on width resize, not height(for mobile browsers)
      if (window.innerWidth !== this.prevWidth ) {
        this.prevWidth = window.innerWidth
        if (window.innerWidth > 890) {
          gsap.set(this.detail, {
            height: "auto",
            autoAlpha: 1,
            duration: 0,
          });
        } else {
          this.isOpen = false
          gsap.to(this.detail, {
            height: this.isOpen ? "auto" : 0,
            autoAlpha: this.isOpen ? 1 : 0,
          });
          this.handleArrow(!this.isOpen)
        }
      }
    });
  }

  toggleAccordion() {
    if (window.innerWidth <= 890) {
      this.handleArrow(this.isOpen)
      gsap.to(this.detail, {
        height: this.isOpen ? 0 : "auto",
        autoAlpha: this.isOpen ? 0 : 1,
        duration: 0.5,
        ease: "Circ.easeInOut",
        onComplete: () => {
          this.isOpen = !this.isOpen;
        },
      }); 
    }
  }

  handleArrow(condition) {
    condition ? this.cta.classList.remove("opened") : this.cta.classList.add("opened")
  }
}