
import { qs, qsa } from "../utils/lib.js";

export default function PaymentPage() {
    const btns = qsa('.js-button-payment')
    const inputs = qsa('.js-payment-input')

    const CheckInputs = () => {
        inputs.forEach((input, inputIndex) => {
            if (input.checked) {
                btns.forEach((button, buttonIndex) => {
                    button.style.display = "none"
                    if (buttonIndex === inputIndex) {
                        button.style.display = "block"
                    }
                })
            }
        })
    }

    CheckInputs()

    inputs.forEach((input) => {
        input.addEventListener('change', CheckInputs)
    })
    

    

}