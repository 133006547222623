import { qs, qsa, setBodyFixed, map, isMobile } from "../utils/lib";
import { gsap, Expo } from "gsap";

export default function () {
  const els = qsa(".zoom_image");
  const container = qs(".product_zoom");
  const target = qs(".zoom-target");
  els.forEach(function (el) {
    el.addEventListener("click", () => {
      const image = el.getAttribute("data-zoom");
      target.addEventListener("load", () => {
        target.classList.add("loaded");
      });
      target.setAttribute("src", image);
      container.classList.add("active");
      setBodyFixed(true);
    });
    container.addEventListener("click", () => {
      target.setAttribute("src", "");
      target.classList.remove("loaded");
      container.classList.remove("active");
      document.body.style.zoom = 1.0;
      setBodyFixed(false);
    });
    container.addEventListener("mousemove", (e) => {
      if (isMobile()) return;

      let y = e.clientY;
      let val = map(y, 0, window.innerHeight, 0, target.clientHeight - window.innerHeight);

      gsap.to(target, {
        duration: 0.2,
        y: -val,
      });
    });
  });
}
