import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { qs, qsa } from "../utils/lib";

export default class IntersectionObs {
  constructor() {
    this.target = document.querySelectorAll("[data-animation]");
    this.options = {
      rootMargin: "0px 0px -300px 0px",
    };
    this.init();
  }

  init() {
    this.target.forEach((el) => {
      switch (el.dataset.animation) {
        case "text":
          gsap.set(el, {
            opacity: 0,
            yPercent: 10,
            duration: 0,
          });
          break;

        case "double-cols":
          if (qs("img", el)) {
            gsap.set(qs("img", el), {
              yPercent: 10,
              opacity: 0,
              duration: 0,
            });
          }

          break;

        case "influence-title":
          gsap.set(el, {
            opacity: 0,
            y: 10,
            duration: 0,
          });

          break;
        case "lc-quote":
          gsap.set(qs(".about-lc-quote__text", el), {
            opacity: 0,
            y: 20,
            x: -10,
            duration: 0,
          });
          gsap.set(qs(".about-lc-quote_signature", el), {
            opacity: 0,
            x: 10,
            duration: 0,
          });

          break;
        case "fullImg":
          gsap.to(qs(".about-full-img__inner", el), {
            opacity: 0,
            yPercent: 2,
            duration: 0,
          });
          break;
        default:
          break;
      }
    });
    this.initObserver();
  }

  animation(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        switch (entry.target.dataset.animation) {
          case "text":
            gsap.to(entry.target, {
              opacity: 1,
              duration: 1,
              yPercent: 0,
            });
            break;

          case "double-cols":
            if (qs("img", entry.target)) {
              gsap.to(qs("img", entry.target), {
                yPercent: 0,
                opacity: 1,
                duration: 1,
                ease: "Power2.inOut",
              });
            }

            break;

          case "influence-title":
            gsap.to(entry.target, {
              opacity: 1,
              y: 0,
              duration: 1,
            });

            break;
          case "lc-quote":
            const tl = gsap.timeline();
            tl.to(
              qs(".about-lc-quote__text", entry.target),
              {
                opacity: 1,
                y: 0,
                x: 0,
                duration: 1,
                ease: "Power2.inOut",
              },
              "tog"
            );
            tl.to(
              qs(".about-lc-quote_signature", entry.target),
              {
                opacity: 1,
                x: 0,
                duration: 1,
                ease: "Power2.inOut",
              },
              "tog"
            );

            break;
          case "fullImg":
            gsap.to(qs(".about-full-img__inner", entry.target), {
              opacity: 1,
              yPercent: 0,
              duration: 1,
            });
            break;

          default:
            break;
        }
        this.observer.unobserve(entry.target);
      }
    });
  }

  initObserver() {
    this.observer = new IntersectionObserver(this.animation.bind(this), this.options);
    this.target.forEach((el) => {
      this.observer.observe(el);
    });
  }
}
