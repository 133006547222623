import React, { Component } from 'react'
import Store from './store'
import gsap from 'gsap'

export default class WrapBuy extends Component {

  state = {
    product: JSON.parse(this.props.product),
    ...Store.getProps(['cart_count']),
    ...Store.getProps(['cart_open']),
    ...Store.getProps(['cart_items']),
    ...Store.getProps(['close_cart']),
    ...Store.getProps(['cart_total']),
    ...Store.getProps(['checkout']),
    quantity: 1,
    current_size: 0
    //popup_open: false
  }

  async componentDidMount() {
    let c_variant = this.state.product.sizes.filter((e) => e.qty > 0)[0]
    if (c_variant) {
      this.setState({
        current_size: this.state.product.sizes.indexOf(c_variant)
      })
    } else {
      this.checkSize()
    }





    Store.linkState(this, ['cart_count'])
    Store.linkState(this, ['cart_open'])
    Store.linkState(this, ['cart_items'])
    Store.linkState(this, ['close_cart'])
    Store.linkState(this, ['cart_total'])
    Store.linkState(this, ['checkout'])

  }

  checkSize() {
    console.log("CHECK SIZE")
    const size = this.state.product.sizes[this.state.current_size].qty <= 0
    if (size) {
      this.setState({
        cart_cta: this.state.stock_cta
      })
    } else {
      this.setState({
        cart_cta: this.state.add_cta
      })
    }

  }

  async setSize(el) {
    await this.setState({
      current_size: this.state.product.sizes.findIndex(x => x.size === el.target.value),
      quantity: 1
    })

    this.checkSize()
  }
  async addToCart(e) {
    e.preventDefault()
    console.log("ADD")
    console.log(this.state.quantity === 0, this.state.product.sizes[this.state.current_size].qty == 0, this.state.product.sizes[this.state.current_size].qty < 0)
    if (this.state.quantity === 0 || this.state.product.sizes[this.state.current_size].qty == 0 || this.state.product.sizes[this.state.current_size].qty < 0) return

    if (typeof gtag !== 'undefined') {
      gtag('event', 'add_to_cart', {
        "items": [this.state.product.to_google]
      });
      gtag('event', 'add', {
        'event_category': 'cart'
      });
    }



    let itemToAdd = {
      "id": this.state.product.product_id,
      "size": this.state.product.sizes[this.state.current_size],
      "title": this.state.product.title,
      "subtitle": this.state.product.subtitle,
      "price": this.state.product.item_price,
      "price_without_taxes": this.state.product.price_without_taxes,
      "discount": 0,
      "message": this.refs.message.value,
      "discount_without_taxes": 0,
      "weight": this.state.product.weight,
      "compare_price": this.state.product.compare_price,
      "image": this.state.product.image,
      "quantity": this.state.quantity,
      "to_google": this.state.product.to_google
    }


    let cartItems = this.state.cart_items

    if (cartItems.length > 0) {

      let ext = cartItems.find(element => element.size.id == itemToAdd.size.id);
      if (ext) {
        if (ext.quantity == this.state.product.sizes[this.state.current_size].qty) {
          this.setState({
            cart_cta: "No more stock"
          })
          return
        }
        ext.quantity = itemToAdd.quantity + ext.quantity
      } else {
        cartItems.push(itemToAdd)
      }
    } else {
      cartItems.push(itemToAdd)
    }



    cartItems.reverse()

    this.setState({
      cart_items: cartItems
    })
    Store.set("cart_items", cartItems)
    Store.set("add_to_cart", true)
    Store.set("cart_total", this.state.cart_total + this.state.quantity)
    localStorage.setItem('lc_cart', JSON.stringify(this.state.cart_items));

  }




  render() {
    return (
      <>
        <textarea name="" ref="message" id="" className="brown light" maxLength="150" placeholder="Add a Message. Max 150 chars"></textarea>
        <div className="wrap_cta">
          <button
            className="button h8 upcase brown c-light bg-sky button-medium"
            onClick={this.addToCart.bind(this)}
          >
            {this.props.cta}
          </button>
        </div>



      </>

    )
  }
}
