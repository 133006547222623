import gsap from "gsap";

export default class MobileMenuNav {
  constructor(el) {
    this.wrapper = el;
    this.btn = el.querySelector("button");
    this.content = el.querySelector(".menu_slider_content");
    this.btn.addEventListener("click", () => {
      this.animate();
    });
  }

  reset() {
    const all_content = Array.from(document.querySelectorAll(".menu_slider_content.open"));
    all_content.forEach((el) => {
      el.classList.remove("open");

      gsap.to(el, {
        autoAlpha: 0,
        height: 0,
      });
    });
  }
  animate() {
    if (this.content.classList.contains("open")) {
      this.content.classList.remove("open");
      gsap.to(this.content, {
        autoAlpha: 0,
        height: 0,
      });
    } else {
      this.reset();
      this.content.classList.add("open");

      gsap.to(this.content, {
        autoAlpha: 1,
        height: "auto",
        y: 0,
      });
    }
  }
}
