import gsap from "gsap/all";

export default class NavbarSidebar {
  constructor(el, navbar, closeBtn) {
    this.el = el;
    this.navbarIcon = this.el;
    this.navbar = navbar;
    this.closeBtn = closeBtn;
    this.isAnimating = false;
  }

  init() {
    if (this.navbarIcon) {
      this.navbarIcon.addEventListener("click", () => this.openTab());
      this.closeBtn.addEventListener("click", () => this.closeTab());

    }
  }

  openTab() {
    document.querySelector("html").style.overflow = "hidden";
    document.querySelector("body").style.overflow = "hidden";

    if (this.isAnimating) {
      return;
    } else {
      this.isAnimating = true;
      this.navbarIcon.classList.add("disabled");

      gsap.set(this.navbar, {
        x: window.innerWidth > 640 ? "50vw" : "100vw",
        duration: 0,
      });

      this.navbar.classList.remove("close");
      gsap.to(this.navbar, {
        x: 0,
        duration: 0.5,
        ease: "power1.inOut",
        onComplete: () => {
          this.isAnimating = false;
          this.navbarIcon.classList.remove("disabled");
        },
      });
    }
  }

  closeTab() {
    document.querySelector("html").style.overflow = "initial";
    document.querySelector("body").style.overflow = "initial";

    if (this.isAnimating) {
      return;
    } else {
      this.isAnimating = true;
      this.navbarIcon.classList.add("disabled");

      gsap.to(this.navbar, {
        x: window.innerWidth > 1024 ? "50vw" : "100vw",
        duration: 0.5,
        ease: "power1.inOut",
        onComplete: () => {
          this.navbar.classList.add("close");
          this.isAnimating = false;
          this.navbarIcon.classList.remove("disabled");
        },
      });
    }
  }
}
