import gsap from "gsap/all";
import { qs, qsa } from "../../utils/lib";

export default class FiltersAccordionMobile {
  constructor(el) {
    this.accordion = el;
    this.detail = qs(".js-accordion-detail-filter", el);
    this.summary = qs(".js-accordion-summary-filter", el);
    this.isOpen = false;
  }
  init() {
    gsap.set(this.detail, {
      height: 0,
      autoAlpha: 0,
      duration: 0,
    });

    this.summary.addEventListener("click", this.toggleAccordion.bind(this));

    window.addEventListener("resize", () => {
      if (window.innerWidth > 890) {
        gsap.set(this.detail, {
          height: "auto",
          autoAlpha: 1,
          duration: 0,
        });
      } else {
        gsap.to(this.detail, {
          height: this.isOpen ? "auto" : 0,
          autoAlpha: this.isOpen ? 1 : 0,
        });
      }
    });
  }

  toggleAccordion() {
    gsap.to(this.detail, {
      height: this.isOpen ? 0 : "auto",
      autoAlpha: this.isOpen ? 0 : 1,
      duration: 0.5,
      ease: "Circ.easeInOut",
      onComplete: () => {
        this.isOpen = !this.isOpen;
      },
    });
  }
}
