import { qs, qsa, fetchJSON } from "../utils/lib";
import _ from "lodash";

export default class Autocomplete {
  constructor(el) {
    this.el = el;
    this.autocomplete = "";
    this.control = qs("#order_validated_address");
    this.container = "";
    this.container_class = ".new-checkout";
    (this.countries = JSON.parse(qs(".new-checkout__filler").getAttribute("data-countries"))),
      (this.fieldsmap = {
        postal_code: "postal_code",
        locality: "city",
        postal_town: "city",
        country: "country",
        administrative_area_level_2: "state",
        route: "route",
        street_number: "street_number",
      });
  }

  async init() {
    console.log(this.el)
    this.container = this.el.closest(this.container_class);
    console.log(this.container)
    let autocomplete = await new google.maps.places.Autocomplete(this.el, {
      types: ["geocode"],
    });
    this.autocomplete = autocomplete;
    autocomplete.setFields(["address_component"]);
    autocomplete.addListener("place_changed", this.fillAddress.bind(this));
  }

  resetfields() {
    const error = document.querySelector(".address_warning");
    if (error) {
      error.remove();
    }
    const fields = Object.values(this.fieldsmap);
    fields.forEach((e) => {
      console.log(e)
      console.log(this.container)
      const el = this.container.querySelector(`[data-google="${e}"]`);
      el.value = "";
    });
  }

  alertNumber() {
    const wrapper = this.el.parentNode;
    let el = document.createElement("p");
    el.classList.add("h4");
    el.classList.add("address_warning");
    el.innerHTML = "* Missing street number can cause error on your shipping";
    wrapper.appendChild(el);
  }

  fillAddress() {
    this.resetfields();
    if (this.control) {
      this.control.value = "ok";
    }
    const fields = this.autocomplete.getPlace();
    let street_number = true;
    fields.address_components.forEach((c) => {
      const type = c.types[0];
      if (type == "street_number") {
        street_number = false;
      }
      if (this.fieldsmap[type]) {
        const el = this.container.querySelector(`[data-google="${this.fieldsmap[type]}"]`);
        el.value = c.short_name;
      }
    });
    if (street_number) {
      this.alertNumber();
    }
  }
}
