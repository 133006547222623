import { qs, qsa } from "../utils/lib";
class ReportPage {
    constructor(el) {
        this.el = el;
        this.dropdown_components = qsa(".js-dropdown", this.el);
        this.dropdown_btns = qsa(".js-report-dropdown-button", this.el);
        this.dropdown_lists = qsa(".js-report-dropdown", this.el);
        this.dropdown_;
        this.init();
    }
    init() {
        this.dropdown_btns.forEach((btn, i) => {
            btn.addEventListener("click", this.toggleDropdown.bind(this, i));
        });
    }
    toggleDropdown(index) {
        this.dropdown_lists[index].classList.toggle("hidden");
    }

    setSelectValue() {}
}
export default ReportPage;
