import gsap from "gsap";
import { qsa, qs } from "../utils/lib";
import ScrollToPlugin from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

export default class SliderProduct {
  constructor() {
    this.target = qsa("[data-inter]");
    this.active_thumb = 0;
    this.active_img = 0;
    this.thumbs = qsa(".js-product-thumbs");
    this.bigImages = qsa(".scroll_image");
    this.thumb_cont = qs(".product-overview_thumb-cont");
    this.options = {
      rootMargin: "-25%  0px -25% 0px",
    };
  }

  setActiveThumb(img_index) {
    this.active_thumb = img_index;
    this.thumbs.forEach((thumb, i) => {
      if (i == this.active_thumb) {
        thumb.classList.add("active");
        gsap.to(this.thumb_cont, {
          duration: 0.2,
          scrollTo: thumb,
          ease: "circ.inOut",
        });
      } else {
        thumb.classList.remove("active");
      }
    });
  }

  getActiveImg(thumbInd) {
    this.bigImages.forEach((img, i) => {
      if (i == thumbInd) {
        this.active_img = img;
      }
    });
  }

  getPosition(element) {
    var xPosition = 0;
    var yPosition = 0;

    while (element) {
      xPosition += element.offsetLeft - element.scrollLeft + element.clientLeft;
      yPosition += element.offsetTop - element.scrollTop + element.clientTop;
      element = element.offsetParent;
    }

    return { x: xPosition, y: yPosition };
  }

  scrollToImage() {
    this.thumbs.forEach((thumb, i) => {
      thumb.addEventListener("click", () => {
        this.getActiveImg(i);

        gsap.to(qs(".js-slider-product-scroller"), {
          duration: 0.1,
          scrollTo: this.getPosition(this.active_img).y - 200,
          ease: "circ.inOut",
        });
      });
    });
  }

  animation(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.target.dataset.inter == "img-obs-slider") {
          this.setActiveThumb(entry.target.dataset.index);
        }
      }
    });
  }

  initObserver() {
    this.observer = new IntersectionObserver(this.animation.bind(this), this.options);
    this.target.forEach((el) => {
      if (el.tagName === "VIDEO") {
        this.observer.observe(el);
      } else {
        el.addEventListener("load", () => {
          this.observer.observe(el);
        });
      }
    });
  }

  init() {
    this.setActiveThumb(0);
    this.initObserver();
    this.scrollToImage();
  }
}
