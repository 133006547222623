import { qs } from "../../utils/lib";
import Navbar from "./navbar";
import NavbarSidebar from "./navbar_sidebar";
import NavbarMobile from "./navbar-mobile";
// import NavbarAccordion from "./navbar_accordion";

export const NavbarMain = () => {
  if (qs(".main_header_container")) {
    const navbar_js = new Navbar(qs(".main_header_container"));
    navbar_js.init();

    const navbarMobile = new NavbarMobile(qs(".header-mobile"));
    navbarMobile.init();

    // const navbarAccordion = new NavbarAccordion(qs(".header-mobile_container"));
    // navbarAccordion.init();
  }

  if (qs(".navigation .login")) {
    const navbarLogin = new NavbarSidebar(
      qs(".navigation .login"),
      qs(".header-login"),
      qs(".header-login .closeX")
    );
    navbarLogin.init();

    const navbarSearch = new NavbarSidebar(
      qs(".navigation .search"),
      qs(".header-search"),
      qs(".header-search .closeX")
    );
    navbarSearch.init();

    // const navbarCart = new NavbarSidebar(
    //   qs(".navigation .cart"),
    //   qs(".header-cart"),
    //   qs(".header-cart .closeX")
    // );
    // navbarCart.init();

    // const navbarMobileCart = new NavbarSidebar(
    //   qs(".main_header_cart"),
    //   qs(".header-cart"),
    //   qs(".header-cart .closeX")
    // );
    // navbarMobileCart.init();

    const navbarMobileSearch = new NavbarSidebar(
      qs(".navigation .mobile-search"),
      qs(".header-search"),
      qs(".header-search .closeX")
    );
    navbarMobileSearch.init();

    const navbarMobileLogin = new NavbarSidebar(
      qs(".navigation .mobile-login"),
      qs(".header-login"),
      qs(".header-login .closeX")
    );
    navbarMobileLogin.init();
  }
};
