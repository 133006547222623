import gsap from "gsap/all";
import { qs, qsa } from "../utils/lib";

export default class getTheLook {
    constructor(el) {
        this.el = el;
        this.breadcrumbs = qsa("[data-breadcrumb]", this.el);
        this.carousels = qsa("[data-carousels]", this.el);
        this.active = 0;
        this.init();
    }
    init() {
        this.breadcrumbs.forEach((breadcrumb, index) => {
            breadcrumb.addEventListener(
                "click",
                this.setActive.bind(this, index)
            );
        });
    }
    setActive(index) {
        this.active = index;
        this.setBreadcrumbs(this.breadcrumbs);
        this.setCarousels(this.carousels);
    }
    setCarousels(elements) {
        elements.forEach((el, i) => {
            if (i == this.active) {
                el.classList.add("active");
                el.classList.remove("hidden");
            } else {
                el.classList.remove("active");
                el.classList.add("hidden");
            }
        });
    }
    setBreadcrumbs(elements) {
        elements.forEach((el, i) => {
            if (i == this.active) {
                el.classList.add("active");
            } else {
                el.classList.remove("active");
            }
        });
    }
}
