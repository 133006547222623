//sidebar checkout solo per pagina checkout_info.html.erb

import { render } from "react-dom"
import { qs, qsa } from "../utils/lib"
import React, { Component } from 'react'
import CheckoutAccordionMobile from "./checkout_accordion"


export default class CheckoutInfo extends Component {
    state = {
        cart: localStorage.getItem('lc_cart')
    }
    componentDidMount() {
        const id = document.querySelector('#order_cart')
        id.value = this.state.cart
        if (qs(".js-accordion-checkout")) {
            const checkout_accordion = qs(".js-accordion-checkout")
            const checkout_acc = new CheckoutAccordionMobile(checkout_accordion);
            checkout_acc.init()
        }
        
    }

    getProductTotal(i) {
        let total = 0
        const cart = JSON.parse(this.state.cart)
        total = cart[i].price * cart[i].quantity
        return total.toFixed(2)
    }
    getSubtotal() {
        let total = 0
        JSON.parse(this.state.cart).forEach((cartItem) => {
            total += cartItem.price * cartItem.quantity
        })
        return total.toFixed(2)
    }
    render() {
        return (
            <div className="new-checkout__recap js-accordion-checkout">
                <h4 className="new-checkout__recap-title">your bag</h4>
                <div className="new-checkout__summary js-accordion-checkout_summary">
                    <p className="new-checkout__summary-cta js-accordion-checkout_summary-cta">Show order summary</p>
                    <p className="new-checkout__summary-total">€ 160.00</p>
                </div>
                <div className="new-checkout__details-cont js-accordion-checkout_detail">
                    <div className="new-checkout__cart">
                        {
                            JSON.parse(this.state.cart).map((item,i) => (
                                <div key={i} className="new-checkout__item">
                                    <div className="new-checkout__outer-img">
                                        <div className="new-checkout__inner-img">
                                            <img src={item.image} alt="" />
                                            <span className="new-checkout__item-quantity">{item.quantity}</span>
                                        </div>
                                    </div>
                                    <div className="new-checkout__item-info">
                                        <p>{item.title}</p>
                                        <p>{item.size.size}</p>
                                    </div>
                                    <div className="new-checkout__item-price">€ {this.getProductTotal(i)}</div>
                                </div>
                            ))
                        }
                        
                        
                    </div>
                    <div className="new-checkout__payment-voices">
                            <div className="new-checkout__voice">
                                <p className="new-checkout__voice-name">Subtotal</p>
                                <p className="new-checkout__voice-price">€ {this.getSubtotal()}</p>
                            </div>
                            <div className="new-checkout__voice">
                                <p className="new-checkout__voice-name">SHIPPING</p>
                                <p className="new-checkout__voice-note">Calculated at next step</p>
                            </div>
                    </div>
                </div>
            </div>
        )
    }
    
}