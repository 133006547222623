import { qs, qsa } from "../utils/lib";
import SizeguidePopup from "./popup/SizeguidePopup";

export default class SizeGuideInitiator {
  constructor(el) {
    this.buttons = qsa(".js-button-atc", el);
    this.popupButtons = qsa(".js-category");
    this.popupContainers = qsa(".tables-container");
    this.popup = new SizeguidePopup();
  }
  init() {
    this.buttons.forEach((el, index) => {
      el.addEventListener("click", (evt) => {
        qs(".size-guide").classList.remove("disabled");
        for (let i = 0; i < this.popupButtons.length; i++) {
          this.popupButtons[i].classList.remove("active");
          this.popupContainers[i].classList.add("disabled");
        }
        this.popupButtons[index].classList.add("active");
        this.popupContainers[index].classList.remove("disabled");
        this.popup.init();
      });
    });

    this.popupButtons.forEach((el, index) => {
      el.addEventListener("click", (evt) => {
        for (let i = 0; i < this.popupButtons.length; i++) {
          this.popupButtons[i].classList.remove("active");
          this.popupContainers[i].classList.add("disabled");
        }
        this.popupButtons[index].classList.add("active");
        this.popupContainers[index].classList.remove("disabled");
      });
    });
  }
}
