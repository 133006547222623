import gsap from "gsap";
import { qs, qsa } from "../utils/lib";

export default class faqAccordion {
  constructor(el) {
    this.accordion = el;
    this.faqAccordionTextList = qsa(".faq-accordion_text");
    this.faqAccordionPlusList = qsa(".faq-accordion .plus");
    this.faqAccordionMinusList = qsa(".faq-accordion .minus");

    this.faqAccordionText = qs(".faq-accordion_text", el);
    this.faqAccordionPlus = qs(".faq-accordion .plus", el);
    this.faqAccordionMinus = qs(".faq-accordion .minus", el);
    this.isOpen = false;
  }

  init() {
    gsap.set(this.faqAccordionText, {
      height: 0,
      autoAlpha: 0,
      duration: 0,
    });

    this.accordion.addEventListener("click", (evt) => this.toggleAccordion());
  }

  toggleAccordion() {
    gsap.to(this.faqAccordionText, {
      height: this.isOpen ? 0 : "auto",
      autoAlpha: this.isOpen ? 0 : 1,
      duration: 0.5,
      ease: "Circ.easeInOut",
      onComplete: () => {
        this.isOpen = !this.isOpen;
      },
    });

    if (this.isOpen) {
      this.faqAccordionMinus.classList.add("faq-accordion_hide");
      this.faqAccordionPlus.classList.remove("faq-accordion_hide");
    } else {
      this.faqAccordionMinus.classList.remove("faq-accordion_hide");
      this.faqAccordionPlus.classList.add("faq-accordion_hide");
    }
  }
}
