import gsap from "gsap/all";
import { qs } from "../../utils/lib";

export default class OutOfStockPopup {
  constructor(el) {
    this.el = qs(".out-of-stock");
    this.open_btn = qs(".js-out-of-stock");
    this.closeBtn = qs(".out-of-stock_close-btn");
    this.popupContent = qs(".out-of-stock_inner");
    this.display = "flex";
    this.animating = false;
  }

  init() {
    this.open_btn && this.open_btn.addEventListener("click", this.popupIn.bind(this));

    this.closeBtn.addEventListener("click", () => {
      this.closePopup();
    });
  }

  popupIn() {
    document.querySelector("html").style.overflow = "hidden";
    document.querySelector("body").style.overflow = "hidden";

    this.tl = gsap.timeline();
    this.animating = true;

    gsap.to(this.el, {
      display: this.display,
    });

    this.tl.fromTo(
      this.popupContent,
      {
        autoAlpha: 0,
        scale: 0.95,
        y: -50,
      },
      {
        autoAlpha: 1,
        scale: 1,
        ease: "Expo.easeOut",
        duration: 1,
        y: 0,
        onComplete: () => {
          this.animating = false;
        },
      }
    );
  }
  closePopup() {
    document.querySelector("html").style.overflow = "initial";
    document.querySelector("body").style.overflow = "initial";

    this.animating = true;

    gsap.fromTo(
      this.popupContent,
      {
        autoAlpha: 1,
        scale: 1,
        duration: 1,
        y: 0,
      },
      {
        autoAlpha: 0,
        scale: 0.95,
        y: -50,
        ease: "Expo.easeOut",

        onComplete: () => {
          this.animating = false;
        },
      }
    );

    gsap.to(this.el, {
      display: "none",
    });
  }
}
