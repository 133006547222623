import gsap from "gsap";
import { qs } from "../../utils/lib";

export default class NavbarMobile {
  constructor(el) {
    this.el = el;
    this.menu = qs(".main_header_menu");
    this.cart = qs(".main_header_cart");
    this.hmContainer = qs(".header-mobile_container");
    this.menuIsOpen = false;
  }

  init() {
    this.menu &&
    this.menu.addEventListener("click", () => this.openMenu());
  }

  openMenu() {
    if (this.menuIsOpen) {
      document.querySelector("html").style.overflow = "initial";
      document.querySelector("body").style.overflow = "initial";

      gsap.to(this.hmContainer, {
        xPercent: -100,
        onComplete: () => {
          this.menu.src = "/icons/menu.svg";
          this.hmContainer.classList.add("close");
        },
      });
    } else {
      document.querySelector("html").style.overflow = "hidden";
      document.querySelector("body").style.overflow = "hidden";

      this.hmContainer.classList.remove("close");
      this.menu.src = "/icons/Close.svg";

      gsap.fromTo(
        this.hmContainer,
        {
          xPercent: -100,
        },
        {
          xPercent: 0,
        }
      );
    }

    this.menuIsOpen = !this.menuIsOpen;
  }
}
