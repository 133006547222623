import Store from "./store_lib";

export default new Store({
  state: {
    cart_open: false,
    close_cart: false,
    cart_count: 0,
    cart_total: 0,
    cart_items: [],
    add_to_cart: false,
    checkout: false,
    wishlist: [],
  },
});
