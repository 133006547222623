export default class Country {
  constructor(el) {
    this.country_select = document.querySelector("select.country");
    this.province_select = document.querySelector(".state_select");
    this.state_input = document.querySelector(".state_input");
    this.init();
  }

  init() {}
  setCountry() {}
}
