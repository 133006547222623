import Selectr from "mobius1-selectr";
import { fetchJSON } from "../utils/lib";

class OrderProducts {
  constructor(el, className) {
    this.selectEl = el;
    this.className = className;
    this.form = this.selectEl.closest('#filters_form')
    this.queryString = window.location.search;
    this.urlParams = new URLSearchParams(this.queryString);
    this.select = new Selectr(this.selectEl, {
      defaultSelected: false,
      customClass: this.className,
      searchable: false,
    });
    
  }

  init() {
      const params = this.urlParams.get('ordering')
      
    this.select.on('selectr.change', (option) => {
        let path_string = ""
        if (this.queryString.length > 0) {
            if (params && params.length > 0) {
                const new_url = window.location.href.replace(params,option.value)
                window.location.href = new_url
                return
            } else {
                path_string = `&ordering=${option.value}`
            }
        } else {
            path_string = `?ordering=${option.value}`
        }
        window.location.href = `${window.location.href}${path_string}`
    })
  }



}
export default OrderProducts
