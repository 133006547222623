import { qs, qsa } from "../../utils/lib";
import Swiper from "swiper/swiper-bundle";
//import "swiper/swiper-bundle.min.css";
import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

export const PostcardSlider = (el, activeCard, loop) => {
  const swiper = new Swiper(el, {
    direction: "horizontal",
    loop: true,
    lazy: {
      loadPrevNext: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    keyboard: {
      enabled: true,
      onlyInViewport: false,
    },
    initialSlide: activeCard,
  });
};

export default class PostcardsClick {
  constructor() {
    this.cards = qsa(".postcard-grid__cell");
    this.carousel = qs(".postcard-carousel");
    this.closeButton = qs(".postcard-carousel__close-button");
    this.grid = qs(".postcard-grid__flex");
    this.target = this.grid.lastElementChild;
  }

  init() {
    //ScrollBottom TL
    const scrollBottomTL = gsap.timeline();

    //Intersection
    new IntersectionObs();

    //Lenis
    const lenis = new Lenis({
      duration: 1.2,
      easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
      smoothTouch: true,
      infinite: true,
    });

    function raf(time) {
      lenis.raf(time);
      requestAnimationFrame(raf);
    }

    requestAnimationFrame(raf);

    window.onbeforeunload = () => {
      if (window.scrollTo) window.scrollTo(0, 0);
    };
    window.onload = () => {
      if (history && history.scrollRestoration) history.scrollRestoration = "manual";

      scrollBottomTL.to(window, {
        scrollTo: {
          y: "max",
          autoKill: true,
        },
        duration: 350,
      });
    };

    this.cards.forEach((card, i) => {
      card.addEventListener("click", (e) => {
        gsap.to(this.carousel, {
          autoAlpha: 1,
          duration: 0.5,
          ease: "Power1.inOut",
        });

        if (card.dataset.key !== "") {
          PostcardSlider(qs(".js-postcards-carousel"), Number(card.dataset.key));
        } else {
          PostcardSlider(qs(".js-postcards-carousel"), i);
        }

        scrollBottomTL.kill();
        lenis.stop();
      });
    });

    //Close
    this.closeButton.addEventListener("click", () => {
      gsap.to(this.carousel, {
        autoAlpha: 0,
        duration: 0.5,
        ease: "Power1.inOut",
      });
      lenis.start();
    });

    window.addEventListener("keyup", (e) => {
      if (e.key === "Escape") {
        gsap.to(this.carousel, {
          autoAlpha: 0,
          duration: 0.5,
          ease: "Power1.inOut",
        });
        lenis.start();
      }
    });
  }
}

class IntersectionObs {
  constructor() {
    this.target = document.querySelectorAll("[data-postcards]");
    this.options = {
      rootMargin: "-50px 0px 0px 0px",
      threshold: 0.2,
    };
    this.init();
  }

  init() {
    this.target.forEach((el) => {
      switch (el.dataset.postcards) {
        case "img":
          gsap.set(el, {
            opacity: 0,
          });
          break;

        default:
          break;
      }
    });
    this.initObserver();
  }

  animation(entries) {
    entries.forEach((entry, i) => {
      if (entry.isIntersecting) {
        switch (entry.target.dataset.postcards) {
          case "img":
            gsap.to(entry.target, {
              opacity: 1,
              delay: 0.1 * i,
            });
            break;

          default:
            break;
        }
        // this.observer.unobserve(entry.target);
      } else {
        switch (entry.target.dataset.postcards) {
          case "img":
            gsap.set(entry.target, {
              opacity: 0,
             
            });
            break;
          default:
            break;
        }

      }
    });
  }

  initObserver() {
    this.observer = new IntersectionObserver(this.animation.bind(this), this.options);
    this.target.forEach((el) => {
      this.observer.observe(el);
    });
  }
}
