import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import { qs, qsa } from "../../utils/lib";

export const Parallax = () => {
  gsap.to("[data-speed]", {
    yPercent: (i, target) => target.dataset.speed,
    ease: "none",
    scrollTrigger: {
      trigger: ".js-parallax-trigger",
      scrub: true,
    },
  });
};

export const aboutTriggers = () => {
  const lenis = new Lenis({
    duration: 1.2,
    easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
  });

  function raf(time) {
    lenis.raf(time);
    ScrollTrigger.update();
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  // Gallery Orizontal Scroll
  let offset = null;
  let trackTotalWidth = null;
  let windowWidth = null;
  let initted_triggers = [];
  let hScrollImg = qsa(".hScrollImg");

  if (qs(".about-h-scroll_track")) {
    const triggersG = qsa("[data-trigger]");
    const loadedImg = [];
    triggersG.forEach((el) => {
      if (el.dataset.trigger === "gallery-scroll") {
        const isMobile = window.innerWidth < 768;
        let galleryTl = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: isMobile ? "top 25%" : "top 15%",
            scrub: true,
            pin: true,
          },
        });

        hScrollImg.forEach((img) => {
          img.addEventListener("load", () => {
            loadedImg.push(img);
            if (loadedImg.length === hScrollImg.length) {
              trackTotalWidth = qs(".about-h-scroll_track").offsetWidth;
              windowWidth = window.innerWidth;
              offset = -(trackTotalWidth - windowWidth);

              galleryTl.to(".about-h-scroll_track", {
                x: offset,
                ease: "none",
              });
            }
          });
        });
      }
    });
  }
  //  End Gallery Orizontal Scroll

  const triggers = qsa("[data-trigger]");
  triggers.forEach((el) => {
    switch (el.dataset.trigger) {
      case "scroll-right":
        gsap.to(el, {
          x: "10%",
          ease: "none",
          scrollTrigger: {
            trigger: ".about-hero",
            scrub: true,
          },
        });
        break;
      case "scroll-left":
        gsap.to(el, {
          x: "-10%",
          ease: "none",
          scrollTrigger: {
            trigger: ".about-hero",
            scrub: true,
          },
        });
        break;

      case "about-imgs-overlay-animation":
        gsap.to(qs(".js-img-1", el), {
          yPercent: 15,
          ease: "none",
          scrollTrigger: {
            trigger: el,
            scrub: true,
          },
        });
        gsap.to(qs(".js-img-2", el), {
          yPercent: -10,
          ease: "none",
          scrollTrigger: {
            trigger: el,
            scrub: true,
          },
        });
        break;
      case "grid-a":
        const gridA = gsap.to(qs(".img-grid-a-2", el), {
          yPercent: -50,
          ease: "none",
          scrollTrigger: {
            trigger: el,
            scrub: true,
          },
        });

        initted_triggers.push(gridA);
        break;
      case "grid-b":
        const gridB = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            scrub: true,
          },
        });

        gridB.to(
          qs(".img-grid-b-1", el),
          {
            yPercent: 10,
            x: 40,
            ease: "none",
          },
          "grid-b"
        );
        gridB.to(
          qs(".img-grid-b-2", el),
          {
            yPercent: -25,
            x: -100,
            ease: "none",
          },
          "grid-b"
        );

        initted_triggers.push(gridB);
        break;
      case "grid-a-2":
        const gridA2 = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: "top 50%",
            scrub: true,
          },
        });
        gridA2.from(
          qs(".img-grid-a-2-1", el),
          {
            yPercent: -25,
            x: 100,
            ease: "none",
          },
          "grid-a-2"
        );
        gridA2.to(
          qs(".img-grid-a-2-2", el),
          {
            yPercent: 25,
            ease: "none",
          },
          "grid-a-2"
        );
        initted_triggers.push(gridA2);
        break;
      case "grid-c":
        const gridC = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            scrub: true,
          },
        });
        gridC.to(
          qs(".img-grid-c-1", el),
          {
            yPercent: 50,
            x: 100,
            ease: "none",
          },
          "grid-c"
        );
        gridC.to(
          qs(".img-grid-c-2", el),
          {
            yPercent: -10,
            ease: "none",
          },
          "grid-c"
        );
        gridC.to(
          qs(".img-grid-c-3", el),
          {
            yPercent: 5,
            ease: "none",
          },
          "grid-c"
        );
        initted_triggers.push(gridC);

        break;
      case "grid-d":
        const gridDTl = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            scrub: true,
          },
        });

        gridDTl.to(
          qs(".img-grid-d-1", el),
          {
            yPercent: 50,
            ease: "none",
          },
          "grid-d"
        );
        gridDTl.to(
          qs(".img-grid-d-2", el),
          {
            yPercent: -50,
            x: -25,
            ease: "none",
          },
          "grid-d"
        );
        gridDTl.to(
          qs(".img-grid-d-3", el),
          {
            yPercent: 25,
            x: 25,
            ease: "none",
          },
          "grid-d"
        );
        gridDTl.to(
          qs(".img-grid-d-4", el),
          {
            yPercent: 50,
            x: 25,
            ease: "none",
          },
          "grid-d"
        );
        gridDTl.to(
          qs(".img-grid-d-5", el),
          {
            yPercent: -100,
            x: -50,
            ease: "none",
          },
          "grid-d"
        );
        initted_triggers.push(gridDTl);

        break;
      case "grid-e":
        const gridETl = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            scrub: true,
          },
        });

        gridETl.to(
          qs(".img-grid-e-1", el),
          {
            yPercent: 25,
            x: 100,
            ease: "none",
          },
          "grid-e"
        );
        gridETl.to(
          qs(".img-grid-e-2", el),
          {
            yPercent: -10,
            x: 100,
            ease: "none",
          },
          "grid-e"
        );
        gridETl.to(
          qs(".img-grid-e-3", el),
          {
            yPercent: 20,
            x: -25,
            ease: "none",
          },
          "grid-e"
        );
        gridETl.to(
          qs(".img-grid-e-4", el),
          {
            yPercent: -30,
            ease: "none",
          },
          "grid-e"
        );
        gridETl.to(
          qs(".img-grid-e-5", el),
          {
            yPercent: 15,
            x: -100,
            ease: "none",
          },
          "grid-e"
        );
        gridETl.to(
          qs(".img-grid-e-6", el),
          {
            yPercent: 10,
            x: 25,
            ease: "none",
          },
          "grid-e"
        );
        gridETl.to(
          qs(".img-grid-e-7", el),
          {
            yPercent: 100,
            x: -40,
            ease: "none",
          },
          "grid-e"
        );
        gridETl.to(
          qs(".img-grid-e-8", el),
          {
            yPercent: -25,
            x: -50,
            ease: "none",
          },
          "grid-e"
        );
        initted_triggers.push(gridETl);

        break;
      case "grid-f":
        const gridFTl = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            scrub: true,
          },
        });
        gridFTl.to(
          qs(".img-grid-f-1", el),
          {
            yPercent: -5,
            x: 100,
            ease: "none",
          },
          "grid-f"
        );
        gridFTl.to(
          qs(".img-grid-f-2", el),
          {
            yPercent: 100,
            x: -300,
            ease: "none",
          },
          "grid-f"
        );
        gridFTl.to(
          qs(".img-grid-f-3", el),
          {
            yPercent: 50,
            ease: "none",
          },
          "grid-f"
        );
        gridFTl.to(
          qs(".img-grid-f-4", el),
          {
            yPercent: -25,
            x: -100,
            ease: "none",
          },
          "grid-f"
        );
        gridFTl.to(
          qs(".img-grid-f-5", el),
          {
            yPercent: -50,
            ease: "none",
          },
          "grid-f"
        );
        gridFTl.to(
          qs(".img-grid-f-6", el),
          {
            yPercent: 10,
            x: -100,
            ease: "none",
          },
          "grid-f"
        );
        initted_triggers.push(gridFTl);

        break;
      default:
        break;
    }
    const images = document.querySelectorAll("img");

    images.forEach((img) => {
      img.addEventListener("load", () => {
        initted_triggers.forEach((el) => {
          el.scrollTrigger.refresh();
        });
      });
    });
  });
};
