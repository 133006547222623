import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
gsap.registerPlugin(ScrollToPlugin);

export const termsScroller = () => {
  const chapters = document.querySelectorAll(".js-terms-content strong");
  const sidebar = document.querySelector(".js-terms-sidebar");

  chapters.forEach((el, i) => {
    const sidebarEl = document.createElement("button");
    sidebarEl.classList.add("sidebar-el");
    sidebarEl.innerHTML = el.innerHTML;

    sidebar.appendChild(sidebarEl);

    sidebarEl.addEventListener("click", () => {
      gsap.to(window, {
        scrollTo: {
          y: el.offsetTop,
          offsetY: 50,
        },
        ease: "Circ.easeInOut",
      });
    });
  });
};
