import {qs,qsa} from '../utils/lib'
import Selectr from "mobius1-selectr";

export default function LanguageRedirect(el) {
    const select = new Selectr(el, {
        defaultSelected: true,
        customClass: "language_select",
        searchable: false,    
    })
    select.on('selectr.change', (option) => {
        window.location.href = `${option.value}`
    })
    // Mobile code
    const trig_mobile = qs('.js-mobile-lang')
    const langs_mobile_wrap = qs('.js-mobile-list-lang')
    trig_mobile && trig_mobile.addEventListener('click', () => {
        langs_mobile_wrap.classList.toggle('active')
    })
}