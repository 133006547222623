import React, { Component } from 'react'
import Store from './store'
import gsap from 'gsap'

export default class Quickbuy extends Component {

  state = {
    product: JSON.parse(this.props.product),
    ...Store.getProps(['cart_count']),
    ...Store.getProps(['cart_open']),
    ...Store.getProps(['cart_items']),
    ...Store.getProps(['close_cart']),
    ...Store.getProps(['cart_total']),
    ...Store.getProps(['checkout']),
    quantity: 1,
    current_size: 0
    //popup_open: false
  }

  async componentDidMount() {
    let c_variant = this.state.product.sizes.filter((e) => e.qty > 0)[0]
    if (c_variant) {
      this.setState({
        current_size: this.state.product.sizes.indexOf(c_variant)
      })
    } else {
      this.checkSize()
    }





    Store.linkState(this, ['cart_count'])
    Store.linkState(this, ['cart_open'])
    Store.linkState(this, ['cart_items'])
    Store.linkState(this, ['close_cart'])
    Store.linkState(this, ['cart_total'])
    Store.linkState(this, ['checkout'])

  }

  checkSize() {
    console.log("CHECK SIZE")
    const size = this.state.product.sizes[this.state.current_size].qty <= 0
    if (size) {
      this.setState({
        cart_cta: this.state.stock_cta
      })
    } else {
      this.setState({
        cart_cta: this.state.add_cta
      })
    }

  }

  async setSize(el) {
    await this.setState({
      current_size: this.state.product.sizes.findIndex(x => x.size === el.target.value),
      quantity: 1
    })

    this.checkSize()
  }
  async addToCart(e) {
    e.preventDefault()
    if (this.state.quantity === 0 || this.state.product.sizes[this.state.current_size].qty == 0 || this.state.product.sizes[this.state.current_size].qty < 0) return

    if (typeof gtag !== 'undefined') {
      gtag('event', 'add_to_cart', {
        "items": [this.state.product.to_google]
      });
      gtag('event', 'add', {
        'event_category': 'cart'
      });
    }



    let itemToAdd = {
      "id": this.state.product.product_id,
      "size": this.state.product.sizes[this.state.current_size],
      "title": this.state.product.title,
      "subtitle": this.state.product.subtitle,
      "price": this.state.product.item_price,
      "price_without_taxes": this.state.product.price_without_taxes,
      "discount": 0,
      "discount_without_taxes": 0,
      "weight": this.state.product.weight,
      "compare_price": this.state.product.compare_price,
      "image": this.state.product.image,
      "quantity": this.state.quantity,
      "to_google": this.state.product.to_google
    }


    let cartItems = this.state.cart_items

    if (cartItems.length > 0) {

      let ext = cartItems.find(element => element.size.id == itemToAdd.size.id);
      if (ext) {
        if (ext.quantity == this.state.product.sizes[this.state.current_size].qty) {
          this.setState({
            cart_cta: "No more stock"
          })
          return
        }
        ext.quantity = itemToAdd.quantity + ext.quantity
      } else {
        cartItems.push(itemToAdd)
      }
    } else {
      cartItems.push(itemToAdd)
    }



    cartItems.reverse()

    this.setState({
      cart_items: cartItems
    })
    Store.set("cart_items", cartItems)
    Store.set("add_to_cart", true)
    Store.set("cart_total", this.state.cart_total + this.state.quantity)
    localStorage.setItem('lc_cart', JSON.stringify(this.state.cart_items));

  }

  popupIn() {
    this.setState({
      popup_open: true
    })
    document.querySelector("html").style.overflow = "hidden";
    document.querySelector("body").style.overflow = "hidden";

    this.tl = gsap.timeline();
    this.animating = true;

    gsap.to(this.el, {
      display: this.display,
    });

    this.tl.fromTo(
      this.popupContent,
      {
        autoAlpha: 0,
        scale: 0.95,
        y: -50,
      },
      {
        autoAlpha: 1,
        scale: 1,
        ease: "Expo.easeOut",
        duration: 1,
        y: 0,
        onComplete: () => {
          this.animating = false;
        },
      }
    );
  }

  closePopup() {
    document.querySelector("html").style.overflow = "initial";
    document.querySelector("body").style.overflow = "initial";

    this.animating = true;

    gsap.fromTo(
      this.popupContent,
      {
        autoAlpha: 1,
        scale: 1,
        duration: 1,
        y: 0,
      },
      {
        autoAlpha: 0,
        scale: 0.95,
        y: -50,
        ease: "Expo.easeOut",

        onComplete: () => {
          this.animating = false;
        },
      }
    );

    gsap.to(this.el, {
      display: "none",
    });
  }


  render() {
    return (
      <>

        {this.props.alt ? (
          <div className='sample_cont'>
            <button
              className="button h8 upcase brown bg-sky c-light button-medium sample"
              onClick={this.addToCart.bind(this)}
            >
              {this.props.cta} <span className="sample_price">{"€" + JSON.parse(this.props.product).item_price}</span>
            </button>
          </div>
        ) : (
          <button
            className="buy_now_btn"
            onClick={this.addToCart.bind(this)}
          >
            <img loading="lazy" src="/icons/basket_buy_now.svg" alt="" />
          </button>
        )}

        {/*
          this.state.popup_open && (
              <section className="shop-quickbuy">
                  <div className="shop-quickbuy-popup">
                      <img className="closeX" src="/icons/Close.svg" alt="" />
                      <div className="shop-quickbuy-content">
                          <div className="shop-quickbuy-sidebar">
                            {
                              this.state.product_images.length > 0 && this.state.product_images.map((image,i) => (
                                <div><img src={image.image.thumb.url} alt="" /></div>
                              ))
                            }
                          </div>

                          <div className="shop-quickbuy-carousel">
                              <div className="swiper1Col swiper">
                                  <div className="swiper-wrapper">
                                      {
                                        this.state.product_images.length > 0 && this.state.product_images.map((image,i) => (
                                          <div className="swiper-slide" key={i}>
                                            <div className="home-carousel-3slide-card">
                                                <div className="image-container">
                                                    <img className="center" src={image.image.url} alt="" />
                                                </div>
                                            </div>  
                                        </div>
                                        ))
                                      }
  

                                  </div>
                                  <div className="swiper-button-prev"></div>
                                  <div className="swiper-button-next"></div>
                              </div>
                          </div>
                      </div>
                      <div className="shop-quickbuy-center">
                      <div className="add-to-cart">
                          <div className="add-to-cart_prod-name">
                            <h2 className="h5 sainte medium c-dark text-center">{this.state.product_complete.legacy_data.title}</h2>

                          </div>
                          <div 
                              className="js-add_to_cart"
                              data-onesize="<%= @product.onesize? %>"
                              data-translations="<%= t('add_to_cart').to_json %>"
                              data-stock="<%= @product.stock %>" 
                              data-colors="<%= @colors.to_json %>"
                              data-product="<%= @cart_product.to_json %>">ADD TO BAG</div>
                      </div>
                      </div>
                  </div>
              </section>






          )
                                    */}

      </>

    )
  }
}
