import gsap from "gsap/all";
import { qs, qsa } from "../utils/lib";

export default class Accordion {
  constructor(el) {
    this.accordion = el;
    this.summary = qs(".js-accordion-summary", el);
    this.detail = qs(".js-accordion-detail", el);
    this.faqAccordionPlus = qs(".js-accordion .plus", el);
    this.faqAccordionMinus = qs(".js-accordion .minus", el);
    this.isOpen = false;
  }
  init() {
    gsap.set(this.detail, {
      height: 0,
      autoAlpha: 0,
      duration: 0,
    });

    this.accordion.addEventListener("click", this.toggleAccordion.bind(this));
  }

  toggleAccordion() {
    gsap.to(this.detail, {
      height: this.isOpen ? 0 : "auto",
      autoAlpha: this.isOpen ? 0 : 1,
      duration: 0.5,
      ease: "Circ.easeInOut",
      onComplete: () => {
        this.isOpen = !this.isOpen;
      },
    });

    if ((this.faqAccordionPlus, this.faqAccordionMinus))
      if (this.isOpen) {
        this.faqAccordionMinus.classList.add("faq-accordion_hide");
        this.faqAccordionPlus.classList.remove("faq-accordion_hide");
      } else {
        this.faqAccordionMinus.classList.remove("faq-accordion_hide");
        this.faqAccordionPlus.classList.add("faq-accordion_hide");
      }
  }
}
