import Swiper from "swiper/swiper-bundle";
import "swiper/swiper-bundle.min.css";

export const swiper1Col = () => {
    const swiper = new Swiper(".swiper.swiper1Col", {
        direction: "horizontal",
        loop: true,
        lazy: true,
        // initialSlide: -1,
        grabCursor: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    return swiper;
};

export const swiper3Cols = () => {
    const swiper = new Swiper(".swiper.swiper3Cols", {
        direction: "horizontal",
        loop: true,
        lazy: true,
        centeredSlides: true,
        grabCursor: true,
        draggable: false,
        preventClicksPropagation: true,
        simulateTouch: false,
        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 40,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 50,
            },
        },

        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    return swiper;
};

export const swiper5Cols = () => {
    const swiper = new Swiper(".swiper.swiper5Cols", {
        direction: "horizontal",
        effect: "coverflow",
        grabCursor: true,
        loop: true,
        lazy: true,
        centeredSlides: true,
        coverflowEffect: {
            rotate: 0,
            stretch: 0,
            depth: 200,
            modifier: 1,
            slideShadows: false,
        },

        breakpoints: {
            640: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
    });

    return swiper;
};
