import { qs, qsa } from "../utils/lib";
import ProductZoom from "./zoom.js";

export default class productImage {
  constructor(el) {
    this.active = 0;
    this.container = el;
    this.thumbs_cont = qs(".product_images_thumbs");
    this.thumbs = qsa(".js-product-thumbs");
    this.images = qsa(".scroll_image", el);
  }

  init() {
    this.thumbs.forEach((t, i) => {
      t.addEventListener("click", () => {
        this.active = i;
        this.setActive();
      });
    });
    ProductZoom();
  }

  setActive() {
    const el = this.images[this.active];
    window.scroll({
      top: el.offsetTop + 130,
      left: 0,
      behavior: "smooth",
    });
  }
}
