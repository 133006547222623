import React, { Component } from 'react'
import { qs, qsa, setBodyFixed } from '../utils/lib'
import Store from './store'
import _ from 'lodash'

export default class AddToCart extends Component {

    state = {
        items: JSON.parse(this.props.items)
    }

    async componentDidMount() {
    }

    render() {
        return (
            <div>
                {this.state.items.map((item) => (
                    <details>
                        <summary>{item.title}</summary>
                        <div className="return_form_quantity">
                            <label htmlFor="">Quantity to return</label>
                            <div className="select">
                                <select name="" id="">
                                    <option value="0">Do not return</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                </select>
                            </div>
                        </div>
                        <div className="return_form_quantity">
                            <label htmlFor="">Cause of Return</label>
                            <div className="select">
                                <select name="" id="">
                                    <option>Choose an option</option>
                                    <option>It does not look like the picture on the website</option>
                                    <option>The description on the website is inaccurate</option>
                                    <option>I ordered more than one size to see which fits best</option>
                                    <option>Fit/size not as expected</option>
                                    <option>Colour not as expected</option>
                                    <option>Item is faulty</option>
                                    <option>Item is poor quality</option>
                                    <option>Item arrived too late</option>
                                    <option>The wrong item was sent</option>
                                    <option>Item arrived damaged</option>
                                    <option>I changed my mind</option>
                                </select>
                            </div>
                        </div>
                        <div className="return_form_quantity">
                            <label htmlFor="">Notes</label>
                            <textarea name="" id="" cols="30" rows="10"></textarea>
                        </div>

                    </details>
                ))}

            </div>

        )
    }
}
