import { qs, qsa } from "../../utils/lib";
import gsap from "gsap";

export default class Navbar {
  constructor(el) {
    this.el = el;
    this.buttons = qsa(".menu_button");
    this.submenu = qs(".submenu");
    this.submenuContent = qsa(".submenu_content");
    this.logo = qs(".js_header_logo");
    this.header = qs(".main_header_grid");
    this.mini_navbar = qs(".main_header_pre");
    this.full_header = qs(".main_header_container");
    this.closers = qsa(".auto_close_menu")
    this.isOpen = false;
    this.animating = false;
  }

  init() {
    if (!this.header.classList.contains("centered")) {
      this.navScrollLayout();
    }

    this.closers.forEach((button) => {
      button.addEventListener("click", () => {
        gsap.to(this.submenu, {
          opacity: 0,
          duration: 0.3,
          ease: "power2.inOut",
          onComplete: () => {
            button.classList.remove("active");
            this.submenu.classList.remove("open");
            this.isOpen = false;
          },
        });
      })
    })
    this.buttons.forEach((button, index) => {
      button.addEventListener("click", (e) => {
        if (this.isOpen && button.classList.contains("active")) {
          gsap.to(this.submenu, {
            opacity: 0,
            duration: 0.3,
            ease: "power2.inOut",
            onComplete: () => {
              button.classList.remove("active");
              this.submenu.classList.remove("open");
              this.isOpen = false;
            },
          });
          if (window.scrollY <= 5 && qs(".home-hero")) {
            this.header.classList.remove("light-background");
            this.header.classList.add("opacity");
          }

        } else {
          for (let i = 0; i < this.buttons.length; i++) {
            this.buttons[i].classList.remove("active");
            this.submenu.classList.remove("open");
          }

          if (index == this.submenuContent[index].dataset.index) {
            for (let i = 0; i < this.submenuContent.length; i++) {
              this.submenuContent[i].classList.remove("active");
            }

            this.submenuContent[index].classList.add("active");
          }

          button.classList.add("active");
          this.submenu.classList.add("open");
          this.header.classList.add("light-background");
          this.header.classList.remove("opacity");

          gsap.to(
            [this.submenu, this.submenuContent],
            // { opacity: 0 },
            {
              opacity: 1,
              duration: 0.3,
              ease: "power2.inOut",
              onComplete: () => {
                this.isOpen = true;
              },
            }
          );
        }
      });
    });
  }

  navScrollLayout() {
    window.addEventListener("scroll", () => {
      if (qs(".postcard-grid")) {
        gsap.to(this.full_header, {
          translateY: -29,
          duration: 0.1,
          ease: "power2.inOut",
        });
        this.logo.classList.add("small");
        this.header.classList.add("light-background");
      } else {
        if (window.scrollY > 5) {
          gsap.to(this.full_header, {
            translateY: -29,
            duration: 0.1,
            ease: "power2.inOut",
          });

          gsap.to(qs(".header-cart"), {
            translateY: 0,
            duration: 0,
            ease: "power2.inOut",
          });

          // if (window.innerWidth >= 891) {
          //   gsap.to(this.logo, {
          //     height: "65px",
          //     duration: 0.1,
          //     // ease: "power2.inOut"
          //   })
          //   // this.handleResize()
          // }

          this.logo.classList.add("small");
          this.header.classList.add("light-background");
          this.header.classList.remove("opacity");
        } else {
          gsap.to(this.full_header, {
            translateY: 0,
            duration: 0.15,
            ease: "power2.inOut",
          });

          gsap.to(qs(".header-cart"), {
            translateY: -29,
            duration: 0,
            ease: "power2.inOut",
          });

          // if (window.innerWidth >= 891) {
          //   gsap.to(this.logo, {
          //     height: "100px",
          //     duration: 0.1,
          //     // ease: "power2.inOut"
          //   })
          // }
          // this.handleResize()

          this.logo.classList.remove("small");
          this.header.classList.remove("light-background");
          if (qs(".home-hero")) {
            this.header.classList.add("opacity");
          }

        }

      }

    });
  }

  handleResize() {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 891) {
        return;
      } else {
        gsap.to(this.logo, {
          height: "16px",
          duration: 0,
        });
      }
    });
  }
}
