import Swiper from "swiper/swiper-bundle";
//import "swiper/swiper-bundle.min.css";

const HomeSlider = (el) => {
    const swiper = new Swiper(el, {
        direction: 'horizontal',
        loop: true,      
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      });
}
export default HomeSlider