import gsap from "gsap/all";
import { qs, fetchJSON } from "../../utils/lib";
import Selectr from "mobius1-selectr";

export default class NewsletterPopup {
  constructor() {
    this.form = qs('#nl_form')
    this.el = qs(".newsletter-popup");
    this.closeBtn = qs(".newsletter-popup_content .text_close");
    this.maybeLater = qs(".maybe-later");
    this.email = this.el.querySelector('#nl_email')
    this.popupContent = qs(".newsletter-popup_content");
    this.news_btn = qs('.js-subscribe')
    this.side_btn = qs('.nl_button_fixed')
    this.terms = this.el.querySelector('#newsl_privacy')
    this.display = "flex";
    this.animating = false;
    this.email_ok = false
    this.terms_ok = false
    this.select_ok = false
    this.submit = this.el.querySelector('.newsletter-popup-submit')
    this.cookie = this.el.dataset.cookie;
    this.text = this.el.querySelector('.text')
    this.thank_message = this.el.querySelector('.thank_message')
    this.select = this.el.querySelector('select.lang')
    this.select_value = ""
    //this.select_plugin = new Selectr(this.select, {
    //    defaultSelected: true,
    //    customClass: "newsletter_language",
    //    searchable: false,
    //  });
  }

  init() {
    //this.select_plugin.on('selectr.change', (option) => {
    //    this.setSelectValue(option)
    //})
    if (!this.cookie == "true") {
      this.popupIn();
    }
    if (this.news_btn) {
      this.news_btn.addEventListener('click', this.popupIn.bind(this))
    }
    if (this.side_btn) {
      this.side_btn.addEventListener('click', this.popupIn.bind(this))
    }
    this.closeBtn.addEventListener("click", () => {
      this.closePopup();
    });

    this.maybeLater.addEventListener("click", () => {
      this.closePopup();
    });

    this.terms.addEventListener("change", () => {
      this.checkTerms();
    });
    this.form.addEventListener("submit", (e) => {
      e.preventDefault()
      this.submit_form(e);
    });


  }

  popupIn() {
    document.querySelector("html").style.overflow = "hidden";
    document.querySelector("body").style.overflow = "hidden";
    if (this.side_btn) {
      this.side_btn.classList.add("removed")

    }
    this.tl = gsap.timeline();
    this.animating = true;

    gsap.to(this.el, {
      display: this.display,
    });

    this.tl.fromTo(
      this.popupContent,
      {
        autoAlpha: 0,
        scale: 0.95,
        y: -50,
      },
      {
        autoAlpha: 1,
        scale: 1,
        ease: "Expo.easeOut",
        duration: 1,
        y: 0,
        onComplete: () => {
          this.animating = false;
        },
      }
    );
  }
  closePopup() {
    document.querySelector("html").style.overflow = "initial";
    document.querySelector("body").style.overflow = "initial";

    this.animating = true;

    gsap.fromTo(
      this.popupContent,
      {
        autoAlpha: 1,
        scale: 1,
        duration: 1,
        y: 0,
      },
      {
        autoAlpha: 0,
        scale: 0.95,
        y: -50,
        ease: "Expo.easeOut",

        onComplete: () => {
          this.animating = false;
        },
      }
    );

    gsap.to(this.el, {
      display: "none",
    });
  }
  validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  checkEmail() {
    if (this.validateEmail(this.email.value)) {
      this.email_ok = true;
    } else {
      this.email_ok = false;
    }
    this.checkForm();
  }
  checkTerms() {
    if (this.terms.checked) {
      this.terms_ok = true;
    } else {
      this.terms_ok = false;
    }
    this.checkForm();
  }
  setSelectValue(option) {
    this.select_value = option.value
    this.checkSelect()
  }
  checkSelect() {
    if (this.select_value == "notselected") {
      this.select_ok = false
    } else {
      this.select_ok = true
    }
    this.checkForm()
  }
  checkForm() {
    if (this.email_ok && this.terms_ok && this.select_ok) {
      this.submit.classList.remove("disabled");
    }
  }
  async submit_form(e) {

    if (typeof gtag !== "undefined") {
      gtag("event", "submit", {
        event_category: "newsletter",
        event_label: "newsletter_subscribe",
      });
    }
    const myFormData = new FormData(this.form);
    const formDataObj = {};
    myFormData.forEach((value, key) => (formDataObj[key] = value));

    const AUTH_TOKEN = document.querySelector("meta[name=csrf-token]").getAttribute("content");
    const url = "/en/subscribe";
    const post = {
      body: formDataObj,
      email: this.email.value,
      authenticity_token: AUTH_TOKEN,
      language: this.select.value
    };
    const response = await fetchJSON(url, {
      method: "POST",
      body: JSON.stringify(post),
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "xmlhttprequest" /* XMLHttpRequest is ok too, it's case insensitive */,
      },
    })
    if (response == 'ok') {
      e.preventDefault()
      this.text.classList.add('hidden')
      this.thank_message.classList.add('active')

    }



  }

}